import React from "react"
import LayoutComponent from "../../components/Layout.js"
import MRSkan from "../../components/MR.js"
import Knap from "../../components/Knap.js"

const MRSkanningPage = () => (
    <LayoutComponent>
        <h1>MR Skanning</h1>
        <p>I tillegg til vårt store utvalg innenfor røntgentilbehør, er vi også leveringsdyktige på tilbehør til magnetisk resonans/MR-skanning: Her har vi plukket ut noen av våre leverandører, som har fokus på kvalitetskontroll (QA) og treningsfantomer, men også rullestoler, senger, leier, sengeleier, droppstativer, søppelbøtter, avfallssortering, stoler, skamler, antimagnetisk inventar, gåstativer og brannslukkere.</p>
        <h1>Våre leverandører</h1>
        <MRSkan />
        <Knap />
    </LayoutComponent>
)
export default MRSkanningPage